import React, { useState } from 'react';
import styled from 'styled-components';
import { MediaQuery } from 'core/config';
import { ColourTheme, Data, SyncNamespace } from 'core/utilities';
import { useSynchronizedState } from '../hooks';


export enum Theme 
{
    default = 'default',

}

export const THEME = {
    [Theme.default] : {
        background : '#131313',
        sphere : {
            r : 25.0,
            b : 25.0,
            g : 25.0
        },
    },

};


export const COLOUR_MAP = {
    [ColourTheme.DARK] : '#723D46',//'#525174',//'#723D46',//'#8E7C93',//'#485665',//'#795663',//'#202820',

    [ColourTheme.GREEN] : '#B3C0A4',
    [ColourTheme.BLUE] : '#118AB2',
    [ColourTheme.LIGHT] : '#cccccc',

} as Data;

// export const COLOUR_MAP = {
//     [ColourTheme.DARK] : {
//         background : '',
//         sphere : {
//             r : 0,
//             g : 0,
//             b : 0
//         }
//     },

//     [ColourTheme.GREEN] : {

//     },

//     [ColourTheme.BLUE] : {

//     },

//     [ColourTheme.LIGHT] : {

//     }

// } as Data;

export const ColourPicker : React.FunctionComponent = () =>
{
    const [ colour, setColour ] = useSynchronizedState<ColourTheme>(SyncNamespace.THEME_COLOUR, ColourTheme.DARK);
    const [ showOptions, setShowOptions ] = useState<boolean>(false);

    const [ _, setTransition ] = useSynchronizedState<boolean>(SyncNamespace.TOGGLE_FRAG, false);
    const onMouseOver = () => setTransition(true);
    const onMouseOut = () => setTransition(false);

    return (
        <>
            <ColourSquare 
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
                zIndex={3}
                offset={7}
                colour={colour}
                // onClick={selectColor(ColourTheme.DARK)}
            />
            {/* <ColourSquare 
                onMouseOver={() => setShowOptions(true)}
                onMouseOut={() => setShowOptions(false)}
                offset={showOptions ? 23 : 7}
                zIndex={2}
                colour={ColourTheme.GREEN}
                // onClick={selectColor}
            />
            <ColourSquare 
                onMouseOver={() => setShowOptions(true)}
                onMouseOut={() => setShowOptions(false)}
                offset={showOptions ? 39 : 7}
                zIndex={1}
                colour={ColourTheme.BLUE}
                // onClick={selectColor}
            /> */}
        </>
    );
};

type PickerProps = {
    colour : string;
    offset : number;
    zIndex : number;
};

const ColourSquare = styled.div<PickerProps>`
    display: none;
    width: 16px;
    height : 16px;
    position: absolute;
    // cursor: pointer;
    left: -28px;
    top: 7px;
    background-color: ${({ colour }) => COLOUR_MAP[colour]};
    transition: background-color 1s ease-in, top 0.1s ease-in;

    @media ${MediaQuery.tablet} {
        display: block;
    }

    ${({ offset, zIndex }) => (
        `
        top: ${offset}px;
        z-index: ${zIndex};
        `
    )}
`;