export enum Palette {
    WHITE             = '#FFFFFF',
    COD_GREY          = '#121212',
    MINE_SHAFT        = '#2B2B2B',
    BLACK             = '#000000',
    MEDIUM_GREY       = '#2F2F2F',
    RED               = '#FF0000',
    PACIFIC_BLUE      = '#00aabb',
    ROYAL_BLUE        = '#6B5FE1',
    EMINENCE          = '#5B3071',
    DECO              = '#CEDA8D',
    TRANQUIL          = '#E7FEFE',
    LAVENDER_GRAY     = '#B9BBD8',
    JET_STREAM        = '#B9D9CC',
    GREEN_SMOKE       = '#A09D67',
    LAUREL            = '#6F996E',
    MAUVE             = '#FAB2FC',
    KIMBERLY          = '#8175AB',
    PERSIAN_BLUE      = '#2F21B9',
    FOG               = '#E2D9FF',
    ONAHAU            = '#BDEFFF',
    PINK_LACE         = '#FFC9F9',
}