import React from 'react';
import styled from 'styled-components';

export const Footer : React.FunctionComponent = () =>
{
    return (
        <Container>

        </Container>
    );
};

const Container = styled.div`
    width : 100%;
    height : 132px;
    background-color: inherit;
`;