import React from 'react';
import { Canvas } from '@react-three/fiber';
import { Controls } from './Controls';
import { OrthographicCamera, Vector3 } from 'three';
import { Sphere } from './Sphere';

const Scene : React.FunctionComponent = () =>
{
    const camera = new OrthographicCamera(-200, 200, 200, -200, -200, 1500);
    camera.position.set(0, 0, 200);
    camera.lookAt(new Vector3(0, 0, 0));

    return (
        <Canvas
        camera={camera}
        >
            <Controls />
            <Sphere radius={123} />
        </Canvas>
    );
};

export default Scene;