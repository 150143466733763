import React from 'react';
import styled from 'styled-components';

interface SectionProps {
    css?: string;
}

export const Section : React.FunctionComponent<SectionProps> = ({ children, css }) => 
{
    return (
        <Container css={css}>
            {children}
        </Container>
    );
};

const Container = styled.div<{ css?: string; }>`
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;

    ${props => props.css ?? ''}
`;