import IMAGES from 'assets/images';
import { useToast } from 'core/toast';
import { ColourTheme, SyncNamespace } from 'core/utilities';
import { MediaQuery } from 'core/config';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSynchronizedState } from '../hooks';
import { ColourPicker, COLOUR_MAP } from './ColourPicker';

const WALLET_ADDRESS = '0x9c10EbAEB989CFd239679d47B9100dc4ad57A536';

export const About : React.FunctionComponent = () =>
{
    const { setToastMessage } = useToast();
    const [ loading, setLoading ] = useSynchronizedState<boolean>(SyncNamespace.INITIAL_LOAD, true);
    const [ colour ] = useSynchronizedState<ColourTheme>(SyncNamespace.THEME_COLOUR, ColourTheme.DARK);
    const isDarkTheme = colour === ColourTheme.DARK;

    useEffect(() =>
    {
        let isMounted = true;
        if (isMounted) setLoading(false);

        return () =>
        {
            isMounted = false;
        };
    }, []);

    const onLinkClick = (href : string) =>
    {
        return () =>
        {
            window.open(href, href.includes('mailto') ? '_self' : '_blank');
        };
    };

    const onAddressClick = () =>
    {
        setToastMessage('Copied address to clipboard');
        navigator.clipboard.writeText(WALLET_ADDRESS);
    };

    const [ _, setTransition ] = useSynchronizedState<boolean>(SyncNamespace.TOGGLE_FRAG, false);
    const [ _o, shouldOffset ] = useSynchronizedState<boolean>(SyncNamespace.TOGGLE_OFFSET, true);
    const onMouseOver = () => {setTransition(true); shouldOffset(false)};
    const onMouseOut = () => {setTransition(false); shouldOffset(true)};

    return (
        <>
            <MobileOverlay />
            <Container isDarkTheme={isDarkTheme} colour={colour} isLoading={loading}>
                <Title isLoading={loading}>
                    <ColourPicker />
                    Rhys Balevicius
                </Title>
                <WalletAddress 
                    isLoading={loading} 
                    onClick={onAddressClick}
                    onMouseOver={onMouseOver}
                    onMouseOut={onMouseOut}
                >
                    {WALLET_ADDRESS}
                </WalletAddress>
                <Blurb isLoading={loading}>
                    I'm a polyglot software engineer and 
                    <Link 
                        onClick={onLinkClick('https://patents.google.com/?inventor=rhys+balevicius&oq=inventor:(rhys+balevicius)')}
                        onMouseOver={onMouseOver}
                        onMouseOut={onMouseOut}
                    >
                        inventor
                    </Link>
                    based 
                    in Toronto, Canada. Currently exploring augmented reality, 
                    metaverses, and the Web3 space.
                    {'\n\n'}
                    Lead Software Engineer at 
                    <Link 
                        onClick={onLinkClick('https://www.speer.io')}
                        onMouseOver={onMouseOver}
                        onMouseOut={onMouseOut}
                    >
                        Speer Technologies.
                    </Link>
                </Blurb>
                <Socials isLoading={loading}>
                    <SocialLink 
                        src={IMAGES.linkedin}
                        isDarkTheme={isDarkTheme}
                        onClick={onLinkClick('https://www.linkedin.com/in/rhys-balevicius/')}
                        onMouseOver={onMouseOver}
                        onMouseOut={onMouseOut}
                    />
                    <SocialLink 
                        src={IMAGES.github}
                        isDarkTheme={isDarkTheme}
                        onClick={onLinkClick('https://www.github.com/rhys-at-speer')}
                        onMouseOver={onMouseOver}
                        onMouseOut={onMouseOut}
                    />
                    <SocialLink 
                        src={IMAGES.twitter}
                        isDarkTheme={isDarkTheme}
                        onClick={onLinkClick('https://www.twitter.com/_saften')}
                        onMouseOver={onMouseOver}
                        onMouseOut={onMouseOut}
                    />
                    <Email 
                        onClick={onLinkClick('mailto:Rhys Balevicius<development@rhys.tech>')}
                        onMouseOver={onMouseOver}
                        onMouseOut={onMouseOut}
                    >development@rhys.tech</Email>
                </Socials>
            </Container>
        </>
    );
};

type AboutProps = { 
    isLoading?   : boolean; 
    isDarkTheme? : boolean;
    colour?      : string;
};

const loadIn = ({ isLoading } : AboutProps) => (
    isLoading
    ? `
    opacity: 0;
    margin-top: 10px;
    `
    : `
    opacity: 1;
    margin-top: 0;
    `
);

const darkTheme = ({ isDarkTheme, colour } : AboutProps) => (
    isDarkTheme 
    ? `
    color: #bbb;
    border-bottom: 1px rgba(255,255,255,0.1) solid;
    ` 
    : `
    color: #555;
    border-bottom: 1px ${COLOUR_MAP[colour ?? ColourTheme.LIGHT]} solid;
    `
);

const Container = styled.div<AboutProps>`
    display: flex; 
    flex-direction: column;
    position: absolute;
    top: 0;
    margin-top: 130px;
    margin-right: 40px;
    user-select: none;
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "avenir next", avenir, "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial, sans-serif;
    line-height: 30px;
    letter-spacing: 1px;
    padding-bottom: 60px;
    transition: opacity 1.5s linear, color 1s linear, border-bottom 1s linear, margin-left 0.5s linear;

    font-size: 16px;
    margin-left: 40px;

    ${loadIn}
    ${darkTheme}

    margin-top: 130px;

    @media ${MediaQuery.tablet} {
        margin-left: 80px;
        font-size: 20px;
    }

    z-index: 1;
`;

const Title = styled.div<AboutProps>`
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 1px;
    transition: opacity 1.5s linear, margin-top 1s ease-out;
    transition-delay: 0.2s;
    ${loadIn}
`;

const WalletAddress = styled.div<AboutProps>`
    font-size: 10px;
    font-style: italic;
    font-weight: 100;
    opacity: 0.6;
    user-select: text;
    transition: opacity 1.5s linear, margin-top 1s ease-out, margin-bottom 0.5s linear;
    transition-delay: 0.2s;
    ${loadIn}

    margin-top: -4px;
    margin-bottom: 25px;


    @media ${MediaQuery.tablet} {
        margin-bottom: 35px;
    }
`;

const Blurb = styled.div<AboutProps>`
    white-space: pre-line;
    max-width: 535px;
    transition: opacity 1.5s linear, margin-top 1s ease-out;
    transition-delay: 0.4s;
    ${loadIn}
`;

const Link = styled.span<AboutProps>`
    margin-left: 8px;
    margin-right: 8px;
    cursor: pointer;
    border-bottom: solid #5D737E 2px;
    transition: box-shadow .3s;
    :hover {
        box-shadow: inset 0 -1.5em 0 0 #5D737E;
    }
`;

const Email = styled(Link)`
    font-size: 12px;
    position: absolute;
    right: 0;
    top: -5px;
    bottom: -3px;
    border-bottom: solid #723D46 2px;

    :hover {
        box-shadow: inset 0 -1.5em 0 0 #723D46;
    }
`;

const Socials = styled.div<AboutProps>`
    width: 100%;
    height: 18px;
    bottom: -32px;
    position: absolute;
    transition: opacity 1.5s linear, margin-top 1s ease-out;
    transition-delay: 0.5s;
`;

const SocialLink = styled.div<{ isDarkTheme: boolean; src: string; }>`
    margin-right: 17px;
    width: 17px;
    height: 17px;
    display: inline-block;
    background: url(${({ src }) => src});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    cursor: pointer;

    filter: ${({ isDarkTheme }) => (
        isDarkTheme 
            ? 'invert(99%) sepia(1%) saturate(7496%) hue-rotate(206deg) brightness(111%) contrast(98%)'
            : 'invert(14%) sepia(1%) saturate(4017%) hue-rotate(329deg) brightness(99%) contrast(84%)'
    )};
    opacity: 0.2;
    :hover { opacity: 0.75; }
    transition: opacity 0.2s ease-in, filter 0.2s ease-in;
`;

const MobileOverlay = styled.div`
    width : 100%;
    height : 100vh;
    background-color: rgba(20,20,20,0.75);
    top: 0;
    left: 0;
    position: absolute;
    z-index: 0;

    @media ${MediaQuery.tablet} {
        display: none;
    }
`;