import React, { useState } from 'react';
import styled from 'styled-components';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Palette } from 'core/config';
import { Alert, AlertColor } from '@mui/material';

interface Props 
{
    message      : string;
    severity?    : AlertColor;
    clearMessage : () => void;
}

export const Toast : React.FunctionComponent<Props> = ({ 
    message,
    severity,
    clearMessage
}) =>
{
    const [ open, setOpen ] = useState<boolean>(true);
    const handleClose = (event: React.SyntheticEvent<Element, Event>, reason: SnackbarCloseReason) => 
    {
        if (reason === 'clickaway') return;
        setOpen(false);
        clearMessage();
    };

    return (
        <InfoPopup
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            open={open}
            onClose={handleClose}
            message={message}
            action={ 
                <React.Fragment> {/* @ts-ignore */}
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
            }
        />
    );
};

const InfoPopup = styled(Snackbar)`
    position: absolute;
    z-index: 10000;
    user-select:none;
`;