export const fragmentShader = `
    precision highp float;

    uniform sampler2D uMaterial;
    uniform float uR;
    uniform float uG;
    uniform float uB;
    uniform float uLight;
    uniform float uSpike;

    in vec3 worldPosition;
    in vec3 vNormal;
    in mat3 normalMatrix;

    float mysmoothstep(float a, float b, float t) {
        return a + (b - a) * smoothstep(0.0, 1.0, clamp(t, 0.0, 1.0));
    }

    void main() {
        vec3 n = normalize(vNormal);
        //float dist = (length(worldPosition)-60.0)/30.0;
        float dist = 1.0-(length(worldPosition-vec3(0.0,0.0,130.0))-60.0)/110.0;
        //n += normalize(worldPosition) * 0.1*sin(dist*60.0);
        //n = normalize(n);
        vec3 eye = normalize(worldPosition.xyz);
        vec3 r = reflect( eye, vNormal);
        float m = 2.0 * sqrt( pow(r.x, 2.0) + pow(r.y, 2.0) + pow(r.z + 1.2, 2.0) );
        vec2 vN = r.xy / m + 0.5;

        vec3 tint = vec3(
            uR / 255.0,
            uB / 255.0,
            uG / 255.0
        );

        // vec3 mat = texture(uMaterial, vN).rgb * tint;
        // vec3 mat = (n + 1.0) * 0.5;

        vec3 rayDirection = vec3(0.0, 0.0, 1.0);

        vec3 lightPosition  = vec3(0.0, 500.0, 500.0);
        vec3 lightDirection = normalize(lightPosition - worldPosition);

        vec3 halfAngle = normalize(lightDirection + rayDirection);

        float mz = max(dot(n, halfAngle), 0.0); // mark zuckerberg
        vec3 pb = vec3(0.5, 0.5, 0.5);
        
        pb *= pow(mz, 400.0);
        // pb *= pow(mz, 10000000000000.0);

        // vec3 pb = vec3(dot(n, lightDirection));
        

        // vec3 mat = lightDirection;//vec3(dot(n, lightDirection));

        vec3 mat = vec3(length(worldPosition - lightPosition) / 1000.0);

        float k1 = 2.0 * uLight + 1.0;
        float k2 = 3.0 * uLight + 2.0;
        float k3 = 4.0 * uLight + 2.0;

        // float k1 = -1.0 * uSpike + 1.0;
        // float k2 = -2.0 * uSpike + 2.0;
        // float k3 = -2.0 * uSpike + 2.0;

        float relang = 1.0-dot(rayDirection, n);
        float a = sin(relang*k1); // 20
        float b = sin(relang*k2); // 30
        float c = sin(relang*k3); // 40

        // (1,2,2), (4,1,2),

        // gl_FragColor = vec4(0.0, worldPosition.y, 0.0, 1.0);
        // gl_FragColor = vec4(n, 1.0);//vec4(mat, 1.0); //vec4(0.0, 0.0, -worldPosition.z / 700.0, 1.0);
        //gl_FragColor = vec4(pb * 20.0, 1.0);
        //gl_FragColor = vec4(vec3(worldPosition), 1.0);
        //gl_FragColor = vec4(vec3(worldPosition.z+150.0), 1.0);

        gl_FragColor = vec4(vec3(relang*0.9) * (vec3(a,b,c)*0.5+0.5) + pb*0.01, 0.002);
        // gl_FragColor = vec4(vec3(a, b, c), 1.0);
    }
`;


// float mysmoothstep(float a, float b, float t) {
//     return a + (b - a) * smoothstep(0.0, 1.0, clamp(t, 0.0, 1.0));
// }

        // vec3 mat = texture(uMaterial, vN).rgb;
        // vec3 tint = vec3(0.1, 0.2, 1.0);
        // mat.rgb *= tint;
        // mat.rgb *= 1.2;

        // ========================
        // vec3 dark = texture(uLightMaterial, vN).rgb;
        // vec3 light = texture(uDarkMaterial, vN).rgb;
        // vec3 light = texture(uLightMaterial, vN).rgb;
        // vec3 dark = texture(uDarkMaterial, vN).rgb;


        // Transition between two mats
        // vec3 mat = vec3(
        //     mysmoothstep(light.r, dark.r, uLight),
        //     mysmoothstep(light.g, dark.g, uLight),
        //     mysmoothstep(light.b, dark.b, uLight)
        // );


        // Transition between colour and mat
        // mat.rbg *= vec3(
        //     mysmoothstep(1.0, tint.r, uLight),
        //     mysmoothstep(1.0, tint.g, uLight),
        //     mysmoothstep(1.0, tint.b, uLight)
        // );
    
        // Darken
        // mat.rgb *= mysmoothstep(1.0, 0.5, uLight); //0.5;

        // ========================