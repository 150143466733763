import { log } from 'core/utilities';
import React from 'react';

export interface AuthState 
{
    uuid?   : string;
    email?  : string;
    rank?   : string;
    token?  : string;
    error?  : string;
    isAuth  : boolean;
}

export enum AuthType 
{
    REQUEST_LOGIN = 'REQUEST_LOGIN',
    LOGIN_SUCCESS = 'LOGIN_SUCCESS',
    LOGOUT        = 'LOGOUT',
    LOGIN_ERROR   = 'LOGIN_ERROR',
    REFRESH_STATE = 'REFRESH_STATE'
}

export interface AuthPayload
{
    uuid?  : string;
    email? : string;
    rank?  : string;
    token? : string;
}

export interface AuthAction
{
    type     : AuthType;
    payload? : AuthPayload;
    error?   : string;
}

export interface Props 
{
    initialState : AuthState;
    action       : AuthAction;
}

export const initialState : AuthState = {
    uuid   : '',
    email  : '',
    rank   : '',
    token  : '',
    isAuth : false,
    error  : undefined
};

export const AuthReducer: React.Reducer<AuthState, AuthAction> = (initialState, action) => 
{
    switch (action.type) 
    {
    case AuthType.REQUEST_LOGIN:
        log.info('REQUEST_LOGIN');
        return {
            uuid   : '',
            email  : '',
            rank   : '',
            token  : '',
            isAuth : false,
            error  : undefined
        };

    case AuthType.LOGIN_SUCCESS:
        log.info('LOGIN_SUCCESS', action.payload);
        return {
            uuid   : action.payload?.uuid,
            token  : action.payload?.token,
            email  : action.payload?.email,
            rank   : action.payload?.rank,
            isAuth : true,
            error  : undefined
        };

    case AuthType.LOGOUT:
        log.info('LOGOUT');
        return {
            uuid   : '',
            email  : '',
            rank   : '',
            token  : '',
            isAuth : false,
            error  : undefined
        };

    case AuthType.LOGIN_ERROR:
        log.info('LOGIN_ERROR');
        return {
            uuid   : '',
            email  : '',
            rank   : '',
            token  : '',
            isAuth : false,
            error  : action.error
        };

    case AuthType.REFRESH_STATE:
        log.info('REFRESH_STATE', action.payload);
        return {
            ...initialState,
            uuid   : action.payload?.uuid,
            token  : action.payload?.token,
            email  : action.payload?.email,
            rank   : action.payload?.rank,
            isAuth : true,
            error  : undefined
        };
    }
};