import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

type SetState<T> = T | ((t : T) => T);

export const usePublisher = (
    namespace? : string
) => 
{
    const publisher = (detail : any) =>
    {
        window.dispatchEvent(
            new CustomEvent(
                `event_${namespace}`,
                {
                    detail
                }
            )
        );
    };

    return publisher;
};

export const useSubscriber = (
    namespace : string, 
    callback  : any
) : void =>
{
    useEffect(() =>
    {
        window.addEventListener(
            `event_${namespace}`,
            (event : CustomEventInit) => callback(event)
        );

        return () =>
        {
            window.removeEventListener(namespace, callback);
        };
    }, []);
};

export const useSynchronizedState = <T>(
    namespace        : string,
    initalObservable : T,
    syncInitialState  = true
) : [
    T,
    (t : SetState<T>) => void
] =>
{
    const observableId = uuidv4();
    const [ observableState, setState ] = useState<T>(initalObservable);

    if (syncInitialState)
    {
        // Request initial state from extant observables
        setTimeout(() =>
        {
            window.dispatchEvent(
                new CustomEvent(
                    `syncr_${namespace}`,
                    {
                        detail : {
                            requestId : observableId
                        }
                    }
                )
            );
        }, 0);
    }

    const publishUpdate = (
        update : SetState<T>
    ) =>
    {
        window.dispatchEvent(new CustomEvent(`sync_${namespace}`, {
            detail : update
        }));
    };

    useEffect(() =>
    {
        // Listen for updates to state
        const listener = ({ detail } : CustomEventInit) =>
        {
            setState(detail);
        };

        // Listen for the fulfillment of an initial sync request
        const syncFulfillment = ({ detail } : CustomEventInit) => 
        {
            const { requestId, value } = detail;

            if (requestId === observableId) 
            {
                setState(value);
            }
        };

        // Listen for sync requests, and fulfill them
        const syncRequest = ({ detail } : CustomEventInit) => 
        {
            const { requestId } = detail;

            if (requestId !== observableId)
            {
                window.dispatchEvent(
                    new CustomEvent(
                        `syncf_${namespace}`, 
                        {
                            detail : {
                                requestId,
                                value : observableState
                            }
                        }
                    )
                );
            }
        };

        window.addEventListener(`sync_${namespace}`, listener);
        window.addEventListener(`syncf_${namespace}`, syncFulfillment);
        window.addEventListener(`syncr_${namespace}`, syncRequest);

        return () => 
        {
            // Memory leak bad
            window.removeEventListener(`sync_${namespace}`, listener);
            window.removeEventListener(`syncf_${namespace}`, syncFulfillment);
            window.removeEventListener(`syncr_${namespace}`, syncRequest);
        };
    }, []);

    return [
        observableState,
        publishUpdate
    ];
};