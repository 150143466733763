import React from 'react';

type Props = React.HTMLAttributes<HTMLDivElement>;

export const MainLayout : React.FunctionComponent<Props> = ({ children }) =>
{
    return (
        <>
            {children}
        </>
    );
};