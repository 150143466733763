export const DeviceSize = {
    mobileS  : '322px',
    mobileM  : '374px',
    mobileL  : '410px',
    tablet   : '767px',
    laptopS  : '1024px',
    laptopM  : '1366px',
    laptopL  : '1420px',
    desktopS : '1600px',
    desktopM : '1920px',
    desktopL : '2560px'
};

export const MediaQuery = {
    mobileS  : `(min-width: ${DeviceSize.mobileS})`,
    mobileM  : `(min-width: ${DeviceSize.mobileM})`,
    mobileL  : `(min-width: ${DeviceSize.mobileL})`,
    tablet   : `(min-width: ${DeviceSize.tablet})`,
    laptopS  : `(min-width: ${DeviceSize.laptopS})`,
    laptopL  : `(min-width: ${DeviceSize.laptopL})`,
    laptopM  : `(min-width: ${DeviceSize.laptopM})`,
    desktopS : `(min-width: ${DeviceSize.desktopS})`,
    desktopM : `(min-width: ${DeviceSize.desktopM})`,
    desktopL : `(min-width: ${DeviceSize.desktopL})`
};