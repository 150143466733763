import { useState } from 'react';
import { Toast, ToastContext } from 'core/toast';
import { DEFAULT_ERROR_MESSAGE } from 'core/utilities';
import { AlertColor } from '@mui/material';

export const ToastProvider: React.FunctionComponent = ({ children }) => 
{
    const [ message, setMessage ] = useState<string>('');
    const [ severity, setSeverity ] = useState<AlertColor | undefined>();

    const setToastMessage = (message? : string, colour?: AlertColor) => 
    {
        setMessage(message ?? DEFAULT_ERROR_MESSAGE);
        setSeverity(colour ?? 'error');
    };

    const clearMessage = () => 
    {
        setMessage('');
        setSeverity(undefined);
    };

    return (
        <ToastContext.Provider value={{ message, setToastMessage, clearMessage }}>
            {children}
            {!!message && <Toast message={message} severity={severity} clearMessage={clearMessage} />}
        </ToastContext.Provider>
    );
};