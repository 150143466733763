export const NODE_ENV = process.env.REACT_APP_NODE_ENV;
export const IS_DEVELOPMENT = NODE_ENV === 'development';
export const IS_PRODUCTION  = NODE_ENV === 'production';

export const BASE_URL = (() => 
{
    if (IS_PRODUCTION)
    {
        const URL = process.env.REACT_APP_BASE_URL;
        if (URL)
        {
            return URL;
        }
    }

    return process.env.REACT_APP_DEV_BASE_URL ?? 'http://localhost:8000';
})();

export enum ColourTheme
{
    LIGHT = '#efefef',
    DARK  = '#131313',

    GREEN  = '#B3C0A4',
    BLUE   = '#118AB2',
}

export enum SyncNamespace
{
    THEME_COLOUR  = 'theme-colour',
    INITIAL_LOAD  = 'load-initial',
    TOGGLE_FRAG   = 'toggle-frag',
    TOGGLE_OFFSET = 'toggle-offset'
}

/* eslint-disable */
export const noop = () => {};

export const ACCESS_KEY = 'StarterKitAccessToken';
export const REFRESH_KEY = 'StarterKitRefreshToken';
export const ACCESS_TOKEN_TTL = 60 * 60; 

export const DEFAULT_ERROR_MESSAGE = 'An error occurred when submitting your request. Please try again soon.';