import React from 'react';
import jwtDecode from 'jwt-decode';
import { AuthAction, AuthType } from './reducer';
import { 
    ACCESS_KEY, 
    REFRESH_KEY, 
    DEFAULT_ERROR_MESSAGE,
    ACCESS_TOKEN_TTL,
    Data,
    APIResult,
    APIRoute,
    mutate,
    query
} from 'core/utilities';

type AuthActionProps = {
    dispatch : React.Dispatch<AuthAction>;
    payload? : Data;
};

interface RegisterSuccess
{
    message : string;
}

export const register = async (payload : Data) : Promise<APIResult> => 
{
    const {
        success,
        reply,
        error
    } = await mutate<RegisterSuccess>(APIRoute.REGISTER, payload);

    if (success)
    {
        const { message } = reply;

        if (message)
        {
            return {
                success : false,
                reply   : undefined,
                error   : message
            };
        }

        return {
            success,
            reply : undefined
        };
    }

    return {
        success,
        error,
        reply : undefined
    };
};

interface LoginSuccess
{
    uuid    : string;
    rank    : string;
    email   : string;
    refresh : string;
    access  : string;
    message : string;
}

export const login = async ({ dispatch, payload } : AuthActionProps) : Promise<APIResult> => 
{
    dispatch({ type: AuthType.REQUEST_LOGIN });

    const {
        success,
        reply
    } = await mutate<LoginSuccess>(APIRoute.LOGIN, payload);

    let error = DEFAULT_ERROR_MESSAGE;

    if (success)
    {
        const { 
            uuid,
            rank,
            email,
            refresh, 
            access,
            message
        } = reply;

        if (refresh && access)
        {
            localStorage.setItem(REFRESH_KEY, refresh);
            localStorage.setItem(ACCESS_KEY, access);

            dispatch({
                type : AuthType.LOGIN_SUCCESS,
                payload : {
                    uuid,
                    rank,
                    email,
                    token : access
                }
            });

            return {
                success : true,
                reply   : undefined
            };
        }
        else 
        {
            error = message;
        }
    }

    dispatch({ type: AuthType.LOGIN_ERROR, error });
    return {
        success : false,
        reply   : undefined,
        error
    };
};

export const logout = async ({ dispatch } : AuthActionProps) : Promise<void> =>
{
    localStorage.removeItem(REFRESH_KEY);
    localStorage.removeItem(ACCESS_KEY);

    dispatch({ type: AuthType.LOGOUT });
};

interface VerifyTokenSuccess 
{
    email  : string;
    access : string;
}

export const verifyToken = async ({ dispatch } : AuthActionProps) : Promise<void> => 
{
    const token = localStorage.getItem(ACCESS_KEY);

    if (token)
    {
        const { uuid, iat } = jwtDecode(token) as { uuid : string, iat : number; };
        const secondsSinceEpoch = Date.now() / 1000;
        const hasTokenExpired = secondsSinceEpoch - iat > ACCESS_TOKEN_TTL; 

        if (hasTokenExpired)
        {
            const refresh = refreshToken(dispatch);
            await refresh();

            return;
        }
        else 
        {
            const { success, reply } = await query<VerifyTokenSuccess>(APIRoute.USER, { 'Authorization' : `Bearer ${token}` });

            if (success)
            {
                const {
                    email,
                    access
                } = reply;

                dispatch({ type : AuthType.REFRESH_STATE,
                    payload : {
                        uuid,
                        token,
                        email,
                        rank: access
                    }
                });

                return;
            }
        }
    }

    logout({ dispatch });
};

interface RefreshTokenSuccess
{
    access  : string;
    refresh : string;
}

export const refreshToken = (dispatch : React.Dispatch<AuthAction>) : () => Promise<void> => 
{
    let lastAccessTokenRefreshTime = 0;

    return async () : Promise<void> =>
    {
        const currentAccessToken = localStorage.getItem(ACCESS_KEY);
        const currentRefreshToken = localStorage.getItem(REFRESH_KEY);

        if (currentRefreshToken && currentAccessToken)
        {
            const now = Date.now();
            const timeSinceLastRefresh = now - lastAccessTokenRefreshTime;
            if (timeSinceLastRefresh < 45000) return;
    
            // Refresh the token
            const {
                success,
                reply
            } = await query<RefreshTokenSuccess>(APIRoute.REFRESH, { 'Authorization' : `Bearer ${currentRefreshToken}` });

            if (success)
            {
                const {
                    access, 
                    refresh 
                } = reply;

                localStorage.setItem(REFRESH_KEY, refresh);
                localStorage.setItem(ACCESS_KEY, access);

                const { uuid, email, rank } = jwtDecode(access) as { uuid : string; email : string; rank : string; };
                dispatch({
                    type: AuthType.REFRESH_STATE,
                    payload : {
                        token : access,
                        uuid,
                        email,
                        rank
                    }
                });
                lastAccessTokenRefreshTime = now;
            }
            else
            {
                logout({ dispatch });
            }
        }
    };
};