import { Data } from 'core/utilities/interfaces';

const IMAGES = {
    sphereLight : require('./light.jpg').default,
    sphereDark  : require('./dark.jpg').default,

    linkedin : require('./linkedin.svg').default,
    github   : require('./github.svg').default,
    twitter  : require('./twitter.svg').default,
    email    : require('./email.svg').default,
    
} as Data;

export default IMAGES;