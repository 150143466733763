import { createContext, useContext } from 'react';
import { AlertColor } from '@mui/material';
import { noop } from 'core/utilities';

interface ToastValues 
{
    message         : string;
    setToastMessage : (message? : string, colour? : AlertColor) => void;
    clearMessage    : () => void;
}

export const initialToastValues = {
    message         : '', 
    setToastMessage : noop,
    clearMessage      : noop
};

export const ToastContext = createContext<ToastValues>(initialToastValues);

export const useToast = () : ToastValues =>
{
    const context = useContext(ToastContext);
    return context;
};