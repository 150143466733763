import React, { useRef } from 'react';
import { ReactThreeFiber, extend, useThree, useFrame } from '@react-three/fiber';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

extend({ OrbitControls });

declare global { /* eslint-disable  @typescript-eslint/no-namespace */
    namespace JSX {
        interface IntrinsicElements {
            orbitControls: ReactThreeFiber.Object3DNode<OrbitControls, typeof OrbitControls>
        }
    }
}

export const Controls : React.FunctionComponent = () => 
{
    const { gl, camera, viewport } = useThree(); // @ts-ignore
    camera.left = -200 * viewport.aspect; // @ts-ignore
    camera.right = 200 * viewport.aspect;
    camera.updateProjectionMatrix();
    const ref = useRef<OrbitControls>();

    useFrame(() => ref.current && ref.current?.update());

    return (
        <orbitControls 
            ref={ref} 
            args={[ camera, gl.domElement ]} 
            enableZoom={false}
            enablePan={false}
            enableRotate={false}
        />
    );
};
