import React from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@mui/material';
import { Palette } from 'core/config';

interface Props 
{
    css? : string;
}

export const Spinner : React.FunctionComponent<Props> = ({ css }) =>
{
    return (
        <Container css={css}>
            <CircularProgress color="secondary" />
        </Container>
    );
};

const Container = styled.div<Props>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100vh - 200px);
    transition: opacity 300ms ease 0.5s;

    ${({ css } : Props) => css && css}

    .MuiCircularProgress-colorSecondary {
        color: ${Palette.PACIFIC_BLUE};
    }
`;