import React from 'react';
import { PublicRoute } from './PublicRoute';
import { PrivateRoute } from './PrivateRoute';
import { AdminRoute } from './AdminRoute';
import { useAuthorization } from 'core/auth';

export enum RouteAccess
{
    public    = 'public',
    private   = 'private',
    admin     = 'admin'
}

export interface RouteProps
{
    path              : string;
    access            : RouteAccess;
    isRestricted?     : boolean;
    component?        : React.ComponentType;
    privateComponent? : React.ComponentType;
    publicComponent?  : React.ComponentType;
    layout?           : React.ComponentType;
}

const Route : React.FunctionComponent<RouteProps> = ({ access, component, ...props }) =>
{
    const { isAuth, rank } = useAuthorization();

    const isAdmin = isAuth && [ 'admin', 'super_admin' ].includes(rank ?? '');

    switch (access)
    {
    case RouteAccess.public:
        return <PublicRoute component={component} {...props} isAuth={isAuth} />;
    case RouteAccess.private:
        return <PrivateRoute component={component} {...props} isAuth={isAuth} />;
    case RouteAccess.admin:
        return <AdminRoute component={component} {...props} isAdmin={isAdmin} />;
    }
};

export default React.memo(Route);