// @ts-ignore
import ifvisible from 'ifvisible.js'; // TODO typedef
import { useState, useEffect } from 'react';
import { verifyToken, refreshToken } from 'core/auth/actions';
import { useAuthorizationDispatch } from 'core/auth';

export const useAuthToken = () : boolean =>
{
    const [ loading, setLoading ] = useState<boolean>(true);
    const dispatch = useAuthorizationDispatch();

    useEffect(() => 
    {
        let isMounted = true;
        (async () => 
        {
            await verifyToken({ dispatch });
            if (isMounted)
            {
                setLoading(false);
            }
        })();

        const refresh = refreshToken(dispatch);

        const refreshTimer = ifvisible.onEvery(60 * 60, refresh);
        ifvisible.on('wakeup', async () =>
        {
            await refresh();
        });

        return () =>
        {
            isMounted = false;
            ifvisible.off('wakeup');
            refreshTimer.stop();
        };
    }, []);

    return loading;
};