// @ts-ignore
import glsl from 'babel-plugin-glsl/macro';

export const vertexShader = glsl`
    precision highp float;

    #pragma glslify: snoise4 = require(glsl-noise/simplex/4d)

    uniform float uTime;
    uniform float uAmplitude;
    uniform float uFrequency;

    out vec3 worldPosition;
    out vec3 vNormal;

    const float EPSILON = 1.0;

    void main () {
        vNormal = normalize(normal);

        // float distortion = snoise4(vec4(normal * uFrequency, uTime)) * uAmplitude;


        // float distortionA = snoise4(vec4(normal * uFrequency, uTime)) * uAmplitude;
        // float distortionB = snoise4((vec4(1.0, 0.0, 0.0, 0.0) + vec4(normal * uFrequency, uTime)) * 1.0) * uAmplitude;
        // float distortionC = snoise4((vec4(0.0, 1.0, 0.0, 0.0) + vec4(normal * uFrequency, uTime)) * 1.0) * uAmplitude;
        
        // vec3 newPosition = position + (normal * distortion);

        vec3 tangent = normalize(cross(vNormal, vec3(0.0, 0.0, 1.0)));
        vec3 bitangent = normalize(cross(vNormal, tangent));

        // vec3 A = position + vNormal * EPSILON;

        vec3 A = position;
        vec3 B = position + tangent * EPSILON;
        vec3 C = position + bitangent * EPSILON;

        // float distortionA = snoise4(vec4(normal * uFrequency, uTime)) * uAmplitude;
        // float distortionB = snoise4(vec4(tangent, 0.0) * EPSILON + vec4(normal * uFrequency, uTime)) * uAmplitude;
        // float distortionC = snoise4(vec4(bitangent, 0.0) * EPSILON + vec4(normal * uFrequency, uTime)) * uAmplitude;

        float distortionA = snoise4(vec4(A, uTime) * uFrequency / 70.0) * uAmplitude;
        float distortionB = snoise4(vec4(B, uTime) * uFrequency / 70.0) * uAmplitude;
        float distortionC = snoise4(vec4(C, uTime) * uFrequency / 70.0) * uAmplitude;
        
        A += normal * distortionA;
        B += normal * distortionB;
        C += normal * distortionC;

        vec3 AB = normalize(B-A); 
        vec3 AC = normalize(C-A);
        vec3 newNormal = normalize(cross(AB, AC)); 
        vNormal = normalMatrix * newNormal;
        
        vec4 mvp = modelMatrix * vec4(A, 1.0);
        worldPosition = mvp.xyz;
        gl_Position = projectionMatrix * mvp;
    }
`;