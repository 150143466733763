import { ColourTheme, SyncNamespace } from 'core/utilities';
import React from 'react';
import styled from 'styled-components';
import { useSynchronizedState } from 'view/pages/Root/hooks';

export const Navbar : React.FunctionComponent = () =>
{
    const [ _, setDarkMode ] = useSynchronizedState<ColourTheme>(SyncNamespace.THEME_COLOUR, ColourTheme.DARK);

    
    return (
        <Container onClick={() => setDarkMode(ColourTheme.DARK)}>

        </Container>
    );
};

const Container = styled.div`
    display : flex;
    position: fixed;
    z-index: 10000;
    width : 100%;
    height : 132px;
    background-color : transparent;
    justify-content : space-between;
`;