import { 
    BASE_URL, 
    Data,
    DEFAULT_ERROR_MESSAGE
} from 'core/utilities';

export enum HTTPMethod
{
    GET   = 'GET',
    PUT   = 'PUT',
    POST  = 'POST',
    PATCH = 'PATCH',
    HEAD  = 'HEAD',
}

export interface APIResult<Payload = void>
{
    success : boolean;
    reply   : Payload;
    error?  : string;
}

export enum APIRoute 
{
    REGISTER     = 'api/register',
    LOGIN        = 'api/login',
    VERIFY       = 'api/verify',
    REFRESH      = 'api/token/refresh',
    USER         = 'api/user'
}

export const query = async <Payload>(
    route : APIRoute, 
    headers : Data = {}
) : Promise<APIResult<Payload>> =>
{
    const options = {
        method : HTTPMethod.GET,
        headers : {
            'Content-Type' : 'application/json',
            ...headers
        }
    };

    try
    {
        const response = await fetch(`${BASE_URL}/${route}`, options);
        const reply    = await response.json();

        return {
            success : true,
            reply
        };
    }
    catch (error)
    {
        return {
            success : false,
            reply   : {} as Payload,
            error   : DEFAULT_ERROR_MESSAGE
        };
    }
};

export const mutate = async <Payload>(
    route : APIRoute, 
    payload : Data = {}, 
    headers : Data = {}, 
    method : HTTPMethod = HTTPMethod.POST
) : Promise<APIResult<Payload>> =>
{
    const options = {
        method,
        headers : {
            'Content-Type' : 'application/json',
            ...headers
        },
        body : JSON.stringify(payload)
    };

    try
    {
        const response = await fetch(`${BASE_URL}/${route}`, options);
        const reply    = await response.json();

        return {
            success : true,
            reply
        };
    }
    catch (error)
    {
        return {
            success : false,
            reply   : {} as Payload,
            error   : DEFAULT_ERROR_MESSAGE
        };
    }
};