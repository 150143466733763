import React from 'react';
import { StaticContext } from 'react-router';
import { 
    Route as ReactRoute, 
    Redirect, 
    RouteComponentProps, 
    RouteProps 
} from 'react-router-dom';

interface PublicRouteProps extends RouteProps
{
    isAuth?           : boolean;
    isRestricted?     : boolean;
    layout?           : React.ComponentType;
    component?        : React.ComponentType;
    privateComponent? : React.ComponentType;
}

type RenderProps = RouteComponentProps<{ [x: string]: string | undefined; }, StaticContext, unknown>;

export const PublicRoute : React.FunctionComponent<PublicRouteProps> = ({ 
    isAuth, 
    isRestricted, 
    layout : Layout, 
    component : Component = () => (<></>),
    privateComponent : PrivateComponent,
    ...rest 
}) =>
{
    const render = (props : RenderProps) =>
        (
            isAuth && isRestricted ? (
                PrivateComponent === undefined ? (
                    <Redirect to="/" />
                ) : (
                    Layout === undefined ? (
                        <PrivateComponent {...props} {...rest} />
                    ) : (
                        <Layout>
                            <PrivateComponent {...props} {...rest} />
                        </Layout>
                    )
                )
            ) : (
                Layout === undefined ? (
                    <Component {...props} {...rest} />
                ) : (
                    <Layout>
                        <Component {...props} {...rest} />
                    </Layout>
                )
            )
        );

    return <ReactRoute render={render} />;
};